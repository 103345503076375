/*
 * @Author: LZH
 * @Date: 2023-11-03 10:26:21
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-07 04:18:02
 * @Description: 
 */
// const baseconfig = { //测试
//   xinextendUrlapi: 'https://apidev.xin-ptt.com:9001/pocextend',
//   apiAudioUrl: "http://apidev.xin-ptt.com/pocAudio", //测试录音文件平台
//   gpsUrlapi: "http://apidev.xin-ptt.com/pocgps", //测试定位地址
//   mmsUrlapi: "https://apidev.xin-ptt.com:9001/mms", //广播
//   videoUrlapi: "https://apidev.xin-ptt.com:9001/video", //视频
//   sosUrlapi: "https://apidev.xin-ptt.com:9001/sos-ptt03", //sos
//   regionUrlapi: "https://apidev.xin-ptt.com:9001/region", //区域群组
//   enclosureUrlapi: "https://apidev.xin-ptt.com:9001/enclosure-ptt03",                               //电子围栏
//   selfUrlapi: "http://apidev.xin-ptt.com/pocToCApi",
//   getDownPath: "https://apidev.xin-ptt.com:20008",
//   httpXin:'broaddev.xin-ptt.com:10003',
//   typeName: 'xin_poc',
//   ak: "gTV5Y1Vvppy3nUFTHQlIBvo5tVx4bqf8", //测试定位地址
//   tokenSl: { "Content-Type": "application/x-www-form-urlencoded" }
// };


// const baseconfig = {  //正式
//   xinextendUrlapi:'https://proxy.poc-global.com/extend',
//   apiAudioUrl: "https://proxy.poc-global.com/audio", //测试录音文件平台
//   gpsUrlapi: "https://proxy.poc-global.com/gps", //测试定位地址
//   mmsUrlapi: "https://apidev.xin-ptt.com:9001/mms", //广播
//   videoUrlapi: "https://apidev.xin-ptt.com:9001/video", //视频
//   sosUrlapi: "https://proxy.poc-global.com/sos-api", //sos
//   regionUrlapi: "https://apidev.xin-ptt.com:9001/region", //区域群组
//   enclosureUrlapi:"https://proxy.poc-global.com/enclosure-api",      
//   selfUrlapi: "https://proxy.poc-global.com/xintoc",                         //电子围栏
//   getDownPath:"https://apidev.xin-ptt.com:20008",
//   ak: "gTV5Y1Vvppy3nUFTHQlIBvo5tVx4bqf8", //测试定位地址
//   tokenSl:{ "Content-Type": "application/x-www-form-urlencoded" },
//   httpXin:'disp.xinpoc.com:10003',
//   typeName:'xin_poc'
// };

const baseconfig = {  //美国访问正式
  xinextendUrlapi:'https://api.global-ptt.com/extend',
  apiAudioUrl: "https://api.global-ptt.com/audio", //测试录音文件平台
  gpsUrlapi: "https://api.global-ptt.com/gps", //测试定位地址
  mmsUrlapi: "https://apidev.xin-ptt.com:9001/mms", //广播
  videoUrlapi: "https://apidev.xin-ptt.com:9001/video", //视频
  sosUrlapi: "https://api.global-ptt.com/sos-api", //sos
  regionUrlapi: "https://apidev.xin-ptt.com:9001/region", //区域群组
  enclosureUrlapi:"https://api.global-ptt.com/enclosure-api",      
  selfUrlapi: "https://api.global-ptt.com/xintoc",                         //电子围栏
  getDownPath:"https://apidev.xin-ptt.com:20008",
  ak: "gTV5Y1Vvppy3nUFTHQlIBvo5tVx4bqf8", //测试定位地址
  tokenSl:{ "Content-Type": "application/x-www-form-urlencoded" },
  httpXin:'disp.xinpoc.com:10003',
  typeName:'xin_poc'
};

// const baseconfig = {  //武瑞转发
//   xinextendUrlapi:'https://proxy-wurui.poc-global.com/extend',
//   apiAudioUrl: "https://proxy-wurui.poc-global.com/audio", //测试录音文件平台
//   gpsUrlapi: "https://proxy-wurui.poc-global.com/gps", //测试定位地址
//   mmsUrlapi: "https://apidev.xin-ptt.com:9001/mms", //广播
//   videoUrlapi: "https://apidev.xin-ptt.com:9001/video", //视频
//   sosUrlapi: "https://proxy-wurui.poc-global.com/sos-api", //sos
//   regionUrlapi: "https://apidev.xin-ptt.com:9001/region", //区域群组
//   enclosureUrlapi:"https://apidev.xin-ptt.com:9001/enclosure",      
//   selfUrlapi: "https://proxy-wurui.poc-global.com/xintoc",                         //电子围栏
//   getDownPath:"https://apidev.xin-ptt.com:20008",
//   ak: "gTV5Y1Vvppy3nUFTHQlIBvo5tVx4bqf8", //测试定位地址
//   tokenSl:{ "Content-Type": "application/x-www-form-urlencoded" },
//   httpXin:'disp.xinpoc.com:10003',
//   typeName:'xin_poc'
// };

export default baseconfig;
